/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { Menu, Transition } from '@headlessui/react'
import { FC, Fragment, useRef } from 'react'
import { Button } from '@bolt-chat-core/shared-ui'

interface ComponentProps {
  val: any
  index: number
  handleGoSetup: (val: any) => void
  copyProject: (title: string, val: any) => void
  setSelectedProject: any
  setOpenDeleteModal: any
}

interface HeightRefCurrent {
  [key: string]: number | null
}

const ListProjects: FC<ComponentProps> = (props: ComponentProps) => {
  const heightRef = useRef<HeightRefCurrent>({})

  const parseDemographics = (demographics: any) => {
    const parsedDemographics = []

    if (demographics?.agegroup?.from && demographics?.agegroup?.to) {
      parsedDemographics.push(`Age ${demographics.agegroup.from} - ${demographics.agegroup.to}`)
    }
    if (demographics?.children?.label?.length) {
      parsedDemographics.push(`${demographics?.children?.label?.join(', ')}`)
    }
    if (demographics?.employment?.label?.length) {
      parsedDemographics.push(`${demographics?.employment?.label?.join(', ')}`)
    }
    if (demographics?.relationship?.label?.length) {
      parsedDemographics.push(`${demographics?.relationship?.label?.join(', ')}`)
    }
    if (demographics?.gender?.label?.length) {
      parsedDemographics.push(`${demographics?.gender?.label?.join(', ')}`)
    }

    return parsedDemographics
  }

  return (
    <tr className="bg-[#212328] h-[80px] overflow-hidden">
      <td
        className="bg-[#1E2025] text-lg text-white font-medium p-6 xl:p-8 pl-6 cursor-pointer w-[250px]"
        onClick={() => props.handleGoSetup(props.val)}
        data-bolt="project-list-item-title"
      >
        <div>{props.val?.title}</div>
        {props.val.createdAt && (
          <div className="leading-3">
            <small className="opacity-30">{moment(props.val.createdAt).format('DD-MM-YYYY')}</small>
          </div>
        )}
      </td>
      <td className="rounded-l-lg text-sm text-white font-medium p-6 xl:p-8 pr-3 lg:pr-0 whitespace-nowrap">
        <div
          className={`flex flex-row gap-7 lg:gap-0 lg:grid lg:grid-flow-col md:grid-cols-6 lg:grid-cols-7 xl:grid-cols-12 2xl:grid-cols-10 items-center cursor-default`}
        >
          {/* Attendes */}
          {props.val?.setups[0]?.steps?.attendes ? (
            <div className="text-center md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1" data-bolt="project-list-item-attendees">
              <PeopleCountIcon count={props.val?.setups[0]?.steps?.attendes || 0} />
            </div>
          ) : null}
          {/* Location */}
          {props.val?.setups[0]?.steps?.location ? (
            <div className="flex items-center flex-row md:col-span-1 lg:col-span-2 xl:col-span-2 2xl:col-span-2" data-bolt="project-list-item-location">
              <LocationIcon />
              <div
                className={`ml-3`}
                ref={(element: HTMLDivElement) => {
                  heightRef.current[`${props.index}location`] = element?.clientHeight
                }}
                data-bolt="project-list-item-location-text"
              >
                <div
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {props.val?.setups[0]?.steps?.location?.country}
                  {props.val?.setups[0]?.steps?.location?.city.length > 0 && ';'}{' '}
                  {props.val?.setups[0]?.steps?.location?.city?.join(', ')}
                </div>
              </div>
            </div>
          ) : null}
          {/* Category */}
          {props.val?.setups[0]?.steps?.product?.category ||
          (props.val?.setups[0]?.steps?.product?.customCategory &&
            props.val?.setups[0]?.steps?.product?.customCategory?.length > 0) ? (
            <div className="flex items-center md:col-span-2 lg:col-span-2 xl:col-span-3 2xl:col-span-2 pl-2 sm:pl-3" data-bolt="project-list-item-category">
              <div className="flex justify-center">
                <CategoryIcon />
              </div>
              <div
                className={`ml-3`}
                ref={(element: HTMLDivElement) => {
                  heightRef.current[`${props.index}category`] = element?.clientHeight
                }}
              >
                <div style={{ whiteSpace: 'break-spaces' }}>
                  <span>
                    {props.val?.setups[0]?.steps?.product?.category && props.val?.setups[0]?.steps?.product?.category}

                    {props.val?.setups[0]?.steps?.product?.subCategory?.length > 0 && (
                      <>
                        {'; '}
                        {props.val?.setups[0]?.steps?.product?.subCategory?.join(', ')}
                      </>
                    )}

                    {props.val?.setups[0]?.steps?.product?.customCategory?.length > 0 && (
                      <>{props.val?.setups[0]?.steps?.product?.customCategory?.join(', ')}</>
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {/* Demographics */}
          {props.val?.setups[0]?.steps?.demographics?.agegroup?.from ||
          props.val?.setups[0]?.steps?.demographics?.children?.label?.length > 0 ||
          props.val?.setups[0]?.steps?.demographics?.employment?.label?.length > 0 ||
          props.val?.setups[0]?.steps?.demographics?.relationship?.label?.length > 0 ||
          props.val?.setups[0]?.steps?.demographics?.gender?.label?.length > 0 ? (
            <div className="flex items-center whitespace-normal cursor-default md:col-span-2 lg:col-span-3 xl:col-span-6 2xl:col-span-5 pl-2 sm:pl-3" data-bolt="project-list-item-demographics">
              <div className="flex justify-center">
                <DemographicsIcon />
              </div>
              <div
                className="ml-3"
                ref={(element: HTMLDivElement) => {
                  heightRef.current[`${props.index}demog`] = element?.clientHeight
                }}
              >
                {parseDemographics(props.val.setups[0].steps.demographics).join(', ')}
              </div>
            </div>
          ) : null}
        </div>
      </td>
      <td className="rounded-r-lg text-sm text-white font-light py-8 pr-4 pl-0 whitespace-nowrap cursor-default" data-bolt="project-list-item-status">
        {props.val?.status === 'IN_REVIEW' ? (
          <span className="float-right py-2 px-6 bg-[#534530] text-[#FFBA3A] uppercase font-semibold rounded-r-lg  rounded-l-lg tracking-wider">
            In Review
          </span>
        ) : props.val?.status === 'SETUP' ? (
          <span className="float-right py-2 px-6 bg-[#534530] text-[#FFBA3A] uppercase font-semibold rounded-r-lg  rounded-l-lg tracking-wider">
            Setup
          </span>
        ) : props.val?.status === 'LIVE_CHAT' ? (
          <span className="float-right py-2 px-6 bg-[#3E4528] text-[#B6DC3D] uppercase font-semibold rounded-r-lg  rounded-l-lg tracking-wider">
            Live Chat
          </span>
        ) : (
          <span className="float-right py-2 px-6 bg-[#35363B] text-[#FFFFFF] uppercase font-semibold rounded-r-lg  rounded-l-lg tracking-wider">
            Complete
          </span>
        )}
      </td>
      <td className="pr-4">
        <Menu as="div" className="relative inline-block text-left" data-bolt="project-list-item-menu">
          <div>
            <Menu.Button className="inline-flex w-full justify-center text-sm font-medium rounded-full">
              <div className="flex items-center justify-center space-x-1 h-6 hover:animate-pulse">
                <div className="w-[5px] h-[5px] bg-[#d9d9d9] rounded-full"></div>
                <div className="w-[5px] h-[5px] bg-[#d9d9d9] rounded-full"></div>
                <div className="w-[5px] h-[5px] bg-[#d9d9d9] rounded-full"></div>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-800 bg-[#181818] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-center">
              <div>
                <Menu.Item>
                  {() => (
                    <Button
                      onClick={() => props.copyProject(props.val?.title, props.val)}
                      variant="base"
                      color="white"
                      className="font-normal"
                      id="copy-project"
                      data-bolt="project-list-item-copy-project-button"
                    >
                      Copy Project
                    </Button>
                  )}
                </Menu.Item>
              </div>
              <div>
                <Menu.Item>
                  {() => (
                    <Button
                      onClick={() => {
                        props.setSelectedProject(props.val?.id)
                        props.setOpenDeleteModal(true)
                      }}
                      variant="base"
                      color="red"
                      className="font-normal"
                      id="delete-project"
                      data-bolt="project-list-item-delete-project-button"
                    >
                      Delete Project
                    </Button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </td>
    </tr>
  )
}

export { ListProjects }

function PeopleCountIcon(props: { count: number }) {
  return (
    <div
      className="bg-[#DF664D]/[.2] rounded-full p-3 relative w-[44px] h-[44px] max-w-[44px] max-h-[44px] flex items-center justify-center"
      title="Participants"
    >
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.41096 8.5241C7.3246 8.51547 7.22096 8.51547 7.12596 8.5241C5.0705 8.45501 3.43823 6.77092 3.43823 4.69819C3.43823 2.58228 5.14823 0.863647 7.27278 0.863647C9.38869 0.863647 11.1073 2.58228 11.1073 4.69819C11.0987 6.77092 9.46641 8.45501 7.41096 8.5241Z"
          stroke="#DF664D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6723 2.59091C15.3478 2.59091 16.6951 3.94682 16.6951 5.61364C16.6951 7.24591 15.3996 8.57591 13.7846 8.63637C13.7155 8.62773 13.6378 8.62773 13.5601 8.63637"
          stroke="#DF664D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.09265 11.7109C1.00265 13.11 1.00265 15.39 3.09265 16.7805C5.46765 18.3696 9.36265 18.3696 11.7376 16.7805C13.8276 15.3814 13.8276 13.1014 11.7376 11.7109C9.37128 10.1305 5.47628 10.1305 3.09265 11.7109Z"
          stroke="#DF664D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3391 16.4091C15.9609 16.2796 16.5482 16.0291 17.0318 15.6577C18.3791 14.6473 18.3791 12.9805 17.0318 11.97C16.5568 11.6073 15.9782 11.3655 15.365 11.2273"
          stroke="#DF664D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <span className="bg-[#DF664D] text-xs absolute rounded-full py-1 px-2 -top-2 -right-3" data-bolt="project-list-item-attendees-count">{props.count}</span>
    </div>
  )
}

function LocationIcon() {
  return (
    <div
      className="bg-[#68B158]/[.2] rounded-full p-3 w-[44px] h-[44px] max-w-[44px] max-h-[44px] flex items-center justify-center"
      title="Location"
    >
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.00021 10.735C10.4884 10.735 11.6948 9.52862 11.6948 8.04046C11.6948 6.55231 10.4884 5.34592 9.00021 5.34592C7.51205 5.34592 6.30566 6.55231 6.30566 8.04046C6.30566 9.52862 7.51205 10.735 9.00021 10.735Z"
          stroke="#68B158"
          strokeWidth="1.5"
        />
        <path
          d="M1.76288 6.46863C3.46424 -1.01046 14.5447 -1.00182 16.2374 6.47727C17.2306 10.8645 14.5015 14.5782 12.1092 16.8755C10.3733 18.5509 7.62697 18.5509 5.88242 16.8755C3.49878 14.5782 0.769694 10.8559 1.76288 6.46863Z"
          stroke="#68B158"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  )
}

function DemographicsIcon() {
  return (
    <div
      className="bg-[#FFA6BC]/[.2] rounded-full p-3 w-[44px] h-[44px] max-w-[44px] max-h-[44px] flex items-center justify-center"
      title="Demographics"
    >
      <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.8404 9.02409C6.75404 9.01546 6.6504 9.01546 6.5554 9.02409C4.49995 8.955 2.86768 7.27091 2.86768 5.19819C2.86768 3.08228 4.57768 1.36364 6.70222 1.36364C8.81813 1.36364 10.5368 3.08228 10.5368 5.19819C10.5281 7.27091 8.89586 8.955 6.8404 9.02409Z"
          stroke="#FFA6BC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.52209 12.2109C0.43209 13.61 0.43209 15.89 2.52209 17.2805C4.89709 18.8696 8.79209 18.8696 11.1671 17.2805C13.2571 15.8814 13.2571 13.6014 11.1671 12.2109C8.80073 10.6305 4.90573 10.6305 2.52209 12.2109Z"
          stroke="#FFA6BC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

function CategoryIcon() {
  return (
    <div
      className="bg-[#5288D9]/[.2] rounded-full p-3 w-[44px] h-[44px] max-w-[44px] max-h-[44px] flex items-center justify-center"
      title="Categories"
    >
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.6365 13.0936V2.66954C18.6365 1.63318 17.7901 0.864543 16.7624 0.950907H16.7106C14.897 1.10636 12.142 2.03045 10.6047 2.99773L10.4579 3.09273C10.2074 3.24818 9.79286 3.24818 9.54241 3.09273L9.3265 2.96318C7.78922 2.00454 5.04286 1.08909 3.22922 0.942271C2.2015 0.855907 1.36377 1.63318 1.36377 2.66091V13.0936C1.36377 13.9227 2.03741 14.7 2.8665 14.8036L3.11695 14.8382C4.99104 15.0886 7.88422 16.0386 9.54241 16.9455L9.57695 16.9627C9.81013 17.0923 10.1815 17.0923 10.406 16.9627C12.0642 16.0473 14.966 15.0886 16.8488 14.8382L17.1338 14.8036C17.9629 14.7 18.6365 13.9227 18.6365 13.0936Z"
          stroke="#5288D9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 3.37773V16.3323" stroke="#5288D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.32966 5.96864H4.38647" stroke="#5288D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.97738 8.55956H4.38647" stroke="#5288D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}
