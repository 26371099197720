import { create } from "zustand"
import { persist } from "zustand/middleware"
import { Setup } from "@bolt-chat-core/shared-ui"
interface StepState {
  step: number
  increment: () => void
  decrement: () => void
  setStep: (step: number) => void
}

const useStepStore = create<StepState>()(
  persist(
    (set) => ({
      step: 1,
      increment: () => set((state) => ({ step: Math.min(state.step + 1, Setup.totalSteps) })),
      decrement: () => set((state) => ({ step: Math.max(state.step - 1, 1) })),
      setStep: (step: number) => set(() => ({ step: Math.min(Math.max(step, 1), Setup.totalSteps) })),
    }),
    {
      name: "step",
    },
  ),
)

export default useStepStore
