import { useState } from 'react'
import { useRouter } from 'next/router'

const PricingModal = (props: { data: any }) => {
  const [isOpen, setOpen] = useState(props.data?.load)
  const router = useRouter()
  return (
    isOpen && (
      <div className="relative z-[9999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-[#1e2025]  bg-opacity-75 backdrop-blur-sm transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto mac-style-scrollbar">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-2xl bg-[#2B2D34] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="absolute right-7 top-7">
                <button onClick={() => setOpen(!isOpen)}>
                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.4"
                      d="M16.1136 29.75C23.4774 29.75 29.4469 23.7805 29.4469 16.4167C29.4469 9.05286 23.4774 3.08333 16.1136 3.08333C8.74981 3.08333 2.78027 9.05286 2.78027 16.4167C2.78027 23.7805 8.74981 29.75 16.1136 29.75Z"
                      fill="#676F79"
                    />
                    <path
                      d="M17.5271 16.4167L20.5938 13.35C20.9804 12.9633 20.9804 12.3233 20.5938 11.9367C20.2071 11.55 19.5671 11.55 19.1804 11.9367L16.1137 15.0033L13.0471 11.9367C12.6604 11.55 12.0204 11.55 11.6338 11.9367C11.2471 12.3233 11.2471 12.9633 11.6338 13.35L14.7004 16.4167L11.6338 19.4833C11.2471 19.87 11.2471 20.51 11.6338 20.8967C11.8338 21.0967 12.0871 21.19 12.3404 21.19C12.5937 21.19 12.8471 21.0967 13.0471 20.8967L16.1137 17.83L19.1804 20.8967C19.3804 21.0967 19.6337 21.19 19.8871 21.19C20.1404 21.19 20.3937 21.0967 20.5938 20.8967C20.9804 20.51 20.9804 19.87 20.5938 19.4833L17.5271 16.4167Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <div className="bg-[#2B2D34] pt-6 pb-12 px-12 lg:px-24">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3  text-center">
                    <div className="flex justify-center">
                      {props.data?.isSuccessful ? (
                        <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="48.6914" cy="48.7258" r="48" fill="#69B73F" stroke="#69B73F" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M40.7076 58.2691L65.44 33.5367C66.4794 32.4973 68.1645 32.4973 69.2039 33.5367C70.2432 34.576 70.2432 36.2611 69.2039 37.3005L42.5895 63.9149C41.5501 64.9542 39.865 64.9542 38.8257 63.9149L28.1799 53.2691C27.1406 52.2298 27.1406 50.5446 28.1799 49.5053C29.2193 48.4659 30.9044 48.4659 31.9437 49.5053L40.7076 58.2691Z"
                            fill="#212328"
                          />
                        </svg>
                      ) : (
                        <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="49.0361" cy="48.7791" r="48" fill="#ED1C24" stroke="#ED1C24" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M49.7163 67.7865H49.6687C47.5662 67.7865 45.8633 69.5 45.8633 71.6157C45.8633 73.7314 47.5662 75.445 49.6687 75.445H49.7163C51.8188 75.445 53.5218 73.7314 53.5218 71.6157C53.5218 69.5 51.8188 67.7865 49.7163 67.7865Z"
                            fill="#212328"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M49.6925 60.1184C51.8082 60.1184 53.5218 58.4038 53.5218 56.287V30.5404C53.5218 28.4235 51.8082 26.709 49.6925 26.709C47.5769 26.709 45.8633 28.4331 45.8633 30.5499V56.2966C45.8633 58.4134 47.5769 60.1279 49.6925 60.1279V60.1184Z"
                            fill="#212328"
                          />
                        </svg>
                      )}
                    </div>
                    <h3 className="text-2xl mt-8 leading-6 text-white" id="modal-title">
                      {props.data?.isSuccessful ? 'Payment successful' : 'Sorry, Payment failed!'}
                    </h3>
                    <div className="mt-2">
                      <p className="text-xl text-center font-light mt-8">
                        {props.data?.isSuccessful
                          ? `Hooray! You have completed your payment!`
                          : 'Your payment was not successfully processed. Please contact our customer support.'}
                      </p>
                    </div>
                    <button
                      onClick={() => (props.data?.isSuccessful ? setOpen(!isOpen) : router.push('/pricing'))}
                      className={`${
                        props.data?.isSuccessful ? 'bg-[#69B73F] text-[#212328] font-bold' : 'bg-[#ED1C24]'
                      } py-4 px-5 lg:px-16 rounded-xl mt-8 font-semibold`}
                    >
                      {props.data?.isSuccessful ? 'Done' : 'Back to My Orders'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default PricingModal
