/* eslint-disable @typescript-eslint/no-explicit-any */
import Head from 'next/head'
import React, { FC, Fragment, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useRouter } from 'next/router'
import {
  _createProject,
  _deleteProject,
  _getFilteredProjects,
  _getProjects,
  _getReportByProjectId,
  _metaAnalysisCount,
  notifySuccess,
} from '@bolt-chat-core/shared-ui'
import { Button, Confirmation } from '@bolt-chat-core/shared-ui'
import PricingModal from '../../components/pricing-modal'
import { NoProject } from '../../components/project/no-project/no-project.component'
import { ListProjects } from '../../components/project/list-projects/list-projects.component'
import Navbar from '../../components/navbar/navbar.component'
import { Projects } from '@prisma/client/chat'
import { useProjectStore } from '../../stores/project-store'
import useStepStore from '../../stores/setup-step-store'
import { useAuthStore } from '../../stores/auth-store'

type IFilter = 'COMPLETE' | 'LIVE_CHAT' | 'SETUP' | '' | 'IN_REVIEW'

const Project: FC = () => {
  const router = useRouter()
  const status = useAuthStore().user?.status
  const [projects, setProjects] = useState<Projects[] | []>([])
  const [hasMoreProject, setHasMoreProject] = useState(true)
  const [projectIndex, setProjectIndex] = useState(10)
  const [loading, setLoading] = useState(true)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState<IFilter | ''>('')
  const [loadPaymentModal, setLoadPaymentModal] = useState({
    load: false,
    isSuccessful: false,
  })
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState<string>('')
  const [showEmpty, setShowEmpty] = useState<boolean>(false)
  const query = router.query
  const userId = useAuthStore().user?.id
  const role = useAuthStore().user?.role
  const selectedTeam = useAuthStore().selectedTeam
  useEffect(() => {
    const authLocal: any = localStorage.getItem('auth')
    if (!router.isReady) return
    if (userId && selectedTeam) {
      getData(projectIndex)
      getMetaCount()
    } else if (JSON.parse(authLocal) === null || JSON.parse(authLocal).state.accessToken === null) {
      router.push('/auth/login')
    }
    if (query?.payment) {
      setLoadPaymentModal({
        load: true,
        isSuccessful: query?.payment === 'success',
      })
    }
  }, [router.isReady, selectedTeam, userId])

  async function getMetaCount() {
    const response = await _metaAnalysisCount({ saved: false, seen: false, createdById: userId })
    useProjectStore.getState().setMetaCount(response)
  }
  async function getData(lastProjectIndex: number) {
    const response = await _getProjects(0, lastProjectIndex, selectedTeam?.id as string, userId as string)
    if (response.success) {
      setShowEmpty(false)
      setProjects([...response.data])
      response.data?.length > 0 ? setHasMoreProject(true) : setHasMoreProject(false)
      setProjectIndex((prev) => prev + 10)
      setLoading(false)
    }
  }
  async function getFilteredData(filter: IFilter) {
    if (filter !== '') {
      const response = await _getFilteredProjects(0, projectIndex, filter, selectedTeam?.id as string, userId as string)
      if (response.success && Array.isArray(response.data) && response.data.length > 0) {
        setShowEmpty(false)
        setProjects([...response.data])
        response.data?.length > 0 ? setHasMoreProject(true) : setHasMoreProject(false)
        setProjectIndex((prev) => prev + 10)
        setLoading(false)
      } else {
        setShowEmpty(true)
      }
    } else getData(10)
  }

  const copyProject = async (title: string, project: Projects) => {
    const setup = project.setups[0]
    const setups = { step: setup?.step, steps: setup?.steps }
    const response = await _createProject({
      teamId: selectedTeam?.id as string,
      payload: {
        title: `${title} copy`,
        status: 'SETUP',
        projectType: 'AI',
        recruitmentType: project?.recruitmentType,
        redirectUrls: project?.redirectUrls,
        setups: setups,
        settings: project?.settings,
        team: { connect: { id: selectedTeam?.id } },
        customWelcomeMessage: project.customWelcomeMessage,
        translatedCustomWelcomeMessage: project.translatedCustomWelcomeMessage,
      },
    })
    if (response.success) {
      setProjects((prev) => [response.data, ...prev])
      notifySuccess('Project copied!')
    }
  }

  async function createProjectAndRedirect() {
    if (selectedTeam) {
      const response = await _createProject({ teamId: selectedTeam.id })
      if (response.success) {
        useProjectStore.getState().update(response.data)
        useStepStore.getState().setStep(1)
        router.push(`/setup/1/${response.data?.id}`)
      }
    }
  }
  async function handleGoSetup(project: Projects) {
    useProjectStore.getState().update(project)
    if (project?.status == 'LIVE_CHAT') {
      router.push(`/audience/${project.id}`)
    } else if (project?.status == 'COMPLETE') {
      const { data } = await _getReportByProjectId(project.id)
      if (data?.keyInsights?.length) {
        router.push(`/report/${project.id}`)
      } else {
        router.push(`/audience/${project.id}`)
      }
    } else if (project?.status === 'IN_REVIEW') {
      router.push(`/audience/${project.id}`)
    } else {
      router.push(`/setup/1/${project.id}`)
      useStepStore.getState().setStep(1)
    }
  }

  function selectFilter(filter: IFilter) {
    if (filter !== selectedFilter) {
      setSelectedFilter(filter)
      if (filter !== '') {
        getFilteredData(filter)
      } else {
        getData(10)
      }
      setProjectIndex(10)
    }
    setIsFilterOpen(false)
  }

  const deleteProject = async () => {
    setLoading(true)
    const response = await _deleteProject(selectedProject)
    if (response.success) {
      setProjects((prev) => prev.filter((project) => project.id !== selectedProject))
      setOpenDeleteModal(false)
      setSelectedProject('')
      setLoading(false)
      notifySuccess('Project deleted!')
    }
  }
  if (status === 'PENDING' || status === 'REJECTED' || status === undefined) {
    return
  }
  return (
    <>
      <Head>
        <title>AI Projects</title>
      </Head>
      <Navbar context="home" isSidebarOpen={false} setIsSidebarOpen={null} />
      <PricingModal data={loadPaymentModal} />
      <Confirmation
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onConfirm={deleteProject}
        onCancel={() => setOpenDeleteModal(false)}
        title="Delete Project"
        message="Are you sure you want to delete this project?"
        confirmText="Delete"
        actionType="danger"
        theme="dark"
      />

      <section className="h-full gradient-form">
        <div className="container py-12 px-6 h-full contents">
          {/* No project */}
          {loading ? (
            // <LoadingOverlay loading={true} />
            <></>
          ) : projects.length < 1 && !showEmpty ? (
            <NoProject createProjectAndRedirect={createProjectAndRedirect} />
          ) : (
            <>
              <div className="grid mx-3 lg:ml-3 lg:mr-9 2xl:mx-40 items-center flex-wrap h-full g-6">
                <div className="flex items-center justify-end mt-16">
                  <div>
                    <div
                      className="flex justify-between items-center mr-3 tracking-widest ring-2 ring-inset ring-[#292C32] text-white bg-[#212328] pl-4 pr-3 py-2 font-medium text-sm leading-tight rounded shadow-md hover:bg-opacity-80 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out min-w-[9rem] mb-3 cursor-pointer"
                      onClick={() => setIsFilterOpen((prev) => !prev)}
                      data-bolt="project-list-filter-dropdown"
                    >
                      <div>
                        {selectedFilter
                          ? selectedFilter === 'IN_REVIEW'
                            ? 'In Review'
                            : selectedFilter == 'SETUP'
                            ? 'Setup'
                            : selectedFilter == 'LIVE_CHAT'
                            ? 'Live Chat'
                            : selectedFilter == 'COMPLETE'
                            ? 'Complete'
                            : null
                          : 'All'}
                      </div>
                      {isFilterOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                      )}
                    </div>
                    {isFilterOpen && (
                      <div className="flex flex-col ring-2 ring-inset ring-[#292C32] w-[300] h-[300] bg-[#212328] absolute z-10 px-6 py-2 min-w-[9rem] rounded">
                        {[
                          { label: 'All', value: '' },
                          { label: 'Setup', value: 'SETUP' },
                          role && role === 'ADMIN' ? { label: 'In Review', value: 'IN_REVIEW' } : null,
                          { label: 'Live Chat', value: 'LIVE_CHAT' },
                          { label: 'Complete', value: 'COMPLETE' },
                        ]
                          .filter((e): e is { label: string; value: string } => e !== null)
                          .map((e, i) => (
                            <div
                              key={i}
                              className={`py-3 cursor-pointer ${
                                e.value === '' ? 'border-b-2' : ''
                              } border-[#292C32] hover:text-[#1DB29D] transition duration-150 ease-in-out`}
                              onClick={() => selectFilter(e.value as IFilter)}
                              data-bolt="project-list-filter-all"
                            >
                              {e.label}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <Button
                    onClick={() => createProjectAndRedirect()}
                    className="sm:min-w-[13rem] mb-3 font-medium tracking-widest"
                    size="small"
                    dataBolt="project-list-create-project-button"
                  >
                    <div className="flex flex-col sm:flex-row items-center justify-center gap-1">
                      <span>Create</span> <span className="font-semibold">New Project</span>
                    </div>
                  </Button>
                </div>
                {showEmpty ? null : (
                  <div className="overflow-x-auto relative mb-8">
                    <InfiniteScroll
                      dataLength={projects.length}
                      next={selectedFilter === '' ? () => getData(projectIndex) : () => getFilteredData(selectedFilter)}
                      hasMore={hasMoreProject}
                      loader={projects.length !== 0 && loading && <div>Loading...</div>}
                    >
                      {Array.isArray(projects) && projects.length > 0 ? (
                        <table className="w-full overflow-hidden lg:overflow-auto mb-10">
                          <thead>
                            <tr className="cursor-default">
                              <th scope="col" className="text-lg font-semibold text-white px-6 py-4 text-left">
                                Project
                              </th>
                              <th scope="col" className="text-lg  font-semibold text-white px-6 py-4 text-left">
                                Audience
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {projects.map((val, index) => (
                              <Fragment key={index}>
                                <ListProjects
                                  val={val}
                                  index={index}
                                  handleGoSetup={handleGoSetup}
                                  copyProject={copyProject}
                                  setOpenDeleteModal={setOpenDeleteModal}
                                  setSelectedProject={setSelectedProject}
                                />
                                {Array(5)
                                  .fill(0)
                                  .map((x, i) => (
                                    <tr key={i}>
                                      <td></td>
                                    </tr>
                                  ))}
                              </Fragment>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div>No result...</div>
                      )}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default Project
